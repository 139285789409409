<script setup>
import { onMounted, onUnmounted, ref, defineEmits, defineExpose } from 'vue';
import { EventBus } from './EventBus';

const emit = defineEmits(['current-active-scene', 'game-end']);

onMounted(() => {

  EventBus.on('game-end', ({gameState}) => {
    emit('game-end', {gameState})
  });
  EventBus.on('game-update', (data) => {

    emit('game-update', data);
  });

  EventBus.on('current-scene-ready', (scene) => {

    emit('current-scene-ready', scene);
  });

});


</script>

<template>
    <div id="game-container"></div>
</template>