<template>
  <div class="main">
    <div class="titles">
      <div class="title">
        Leaderboard
      </div>
      <div class="subTitle">
        top 100 players worldwide
      </div>
    </div>
    <div class="profileList">
      <Profile v-for="(item, index) in leaders" :key="item.user_id" :index="index + 1"
               :name="`${item.first_name || ''} ${item.last_name || ''}`.trim()"
               :desc="`Max Level: ${item.best_score}`" :value="item.best_score.toLocaleString()"
               class="profile">
        <img src="../assets/images/avatar.png" alt="">
      </Profile>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/Profile";
import { getTop100Leaders } from "@/http";

export default {
  name: "LeaderboardView",
  components: {
    Profile
  },
  data() {
    return {
      leaders: []
    };
  },
  async mounted() {
    try {
      const data = await getTop100Leaders();
      this.leaders = data || [];
    } catch (error) {
      console.error("Failed to fetch leaderboard data:", error);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/styles/mixins";

.main {
  padding: 16px;
}

.titles {
  padding-left: 3px;
  text-align: left;
  margin-bottom: 23px;
}
.profileList {
  overflow: auto;
  height: calc(100vh - 95px);
}
.title {
  @include heavyTitle;
}
.subTitle {
  font-size: 13px;
  line-height: 16px;
  color: rgba(112, 117, 121, 1);
  text-transform: uppercase;
}
.profile {
  margin-bottom: 10px;
}
</style>