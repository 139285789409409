import { createRouter, createWebHistory } from 'vue-router'
import Game from "@/views/Game";
import Leaderboard from "@/views/Leaderboard";
import { showBackButton, hideBackButton } from '@/platforms/telegram/telegram';

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { path: 'game' }
  },
  {
    path: '/game',
    name: 'game',
    component: Game,
    beforeEnter: () => {
      hideBackButton();  // Ensure the back button is hidden when entering the game route
    }
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: Leaderboard,
    beforeEnter: () => {
      showBackButton();  // Show the back button when entering the leaderboard
    },
    beforeRouteLeave: () => {
      hideBackButton();  // Hide the back button when leaving the leaderboard
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router;
