const tg = window.Telegram.WebApp;

// Нужно для вериф пользователя в будущем
const initData = tg.initData;

console.log(initData)

// Разворачиваем WebApp на полный экран
tg.expand();

// Set the title bar color to white
tg.setHeaderColor("#ffffff");

// tg.close() - закрыть Web App

// Получаем параметры текущей темы
const themeParams = tg.themeParams;

tg.disableVerticalSwipes();

export function showBackButton() {
    tg.BackButton.show();
}

export function hideBackButton() {
    tg.BackButton.hide();
}

// Handle the back button press
tg.BackButton.onClick(() => {
    window.history.back();  // This will take you back to the previous route
});

// Пример использования параметров темы
// const backgroundColor = themeParams.bg_color || '#ffffff'; // Цвет фона
// const textColor = themeParams.text_color || '#000000'; // Цвет текста
// const hintColor = themeParams.hint_color || '#888888'; // Цвет подсказок
// const linkColor = themeParams.link_color || '#0088cc'; // Цвет ссылок

console.log(themeParams);