<template>
<div class="profile">
  <div class="index">{{index}}</div>
  <div class="photo" :style="{backgroundColor: `hsl(${avatar_color})`}">
    <div class="avatarText">
      {{name ? name[0] : ''}}
    </div>
  </div>
  <div class="texts">
    <div class="name">{{name}}</div>
    <div class="desc">{{desc}}</div>
  </div>
  <div class="value">{{value}}</div>
</div>
</template>

<script>
import {stringToColor} from "@/utils/stringToColor";

export default {
  name: "ProfileComponent",
  props: {
    index: Number,
    name: String,
    date: String,
    value: String
  },
  computed: {
    avatar_color() {
      return stringToColor(this.name);
    },
    desc() {
      if (!this.date) {
        return '';
      }
      const days = (new Date().getTime() - new Date(this.date).getTime()) / (1000*60*60*24);
      if (days < 1) {
        return 'Today';
      }
      if (days < 2) {
        return 'Yesterday';
      }
      return `${parseInt(days)} days ago`;
    }
  }
}
</script>

<style scoped>
.profile {
  height: 68px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px;
}
.index {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-right: 12px;
}
.photo {
  width: 48px;
  height: 48px;
  margin-right: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatarText {
  font-size: 30px;
  color: white;
  text-transform: uppercase;
}
.texts {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.name {
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;

}
.desc {
  font-size: 14px;
  line-height: 20px;
  color: rgba(112, 117, 121, 1);
}
.value {
  font-size: 16px;
  line-height: 22px;
  color: rgba(112, 117, 121, 1);
  margin-left: auto;
}
</style>