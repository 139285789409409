// const API_URL = process.env["VUE_APP_API_URL"];
const USE_NGROK = process.env["VUE_APP_USE_NGROK"] === 'true';  // Check if ngrok should be used
const IS_PLAYDECK = process.env["VUE_APP_IS_PLAYDECK"] === 'true';

const API_URL = 'https://block-ton-backend.onrender.com';

console.log(USE_NGROK ? "USE_NGROK=true" : "USE_NGROK=false");

function getHeadersWithAuthToken() {
    let token = localStorage.getItem('token');
    let headers = token ? {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    } : {};
    
    if (USE_NGROK) {
        headers['ngrok-skip-browser-warning'] = '69420';  // Add ngrok header only if required
    }
    
    return headers;
}

export async function verify(initData) {
    if (!window.Telegram || !window.Telegram.WebApp) {
        return false;
    }
    initData = initData || window.Telegram.WebApp.initData;

    console.log(initData);
    let headers = {
        'Content-Type': 'application/json'
    };

    if (USE_NGROK) {
        headers['ngrok-skip-browser-warning'] = '69420';  // Add ngrok header only if required
    }

    const response = await fetch(API_URL + '/auth/verify-webapp', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(initData)
    });

    console.log(response);

    return response.json ? response.json() : response;
}

export async function accountInfo() {
    const response = await fetch(API_URL + '/auth/user-info', {
        method: 'POST',
        headers: getHeadersWithAuthToken()
    });

    return await response.json();
}

export async function resetGame(user_id) {
    const response = await fetch(API_URL + `/ads/2f4326ca-1305-4ec9-a10d-40a031df6dd8/new-game?user_id=${user_id}`, {
        method: 'GET',
        headers: getHeadersWithAuthToken()
    });

    return await response.json();
}

export async function newGame() {
    const response = await fetch(API_URL + '/game/new', {
        method: 'POST',
        headers: getHeadersWithAuthToken()
    });

    return await response.json();
}

export async function getActiveGame() {
    const response = await fetch(API_URL + '/game/active', {
        method: 'GET',
        headers: getHeadersWithAuthToken()
    });

    return await response.json();
}

export async function updateScore({session_id, step_score, game_state}) {
    const response = await fetch(API_URL + '/game/update', {
        method: 'POST',
        headers: getHeadersWithAuthToken(),
        body: JSON.stringify({session_id, step_score, game_state})
    });

    const data = await response.json();
    return data?.data;
}

export async function endGame({session_id}) {
    const response = await fetch(API_URL + '/game/end', {
        method: 'POST',
        headers: getHeadersWithAuthToken(),
        body: JSON.stringify({session_id})
    });

    return await response.json();
}


export async function getTop100Leaders() {
    let headers = {
        'Content-Type': 'application/json'
    };

    if (USE_NGROK) {
        headers['ngrok-skip-browser-warning'] = '69420';  // Add ngrok header only if required
    }

    const response = await fetch(API_URL + '/leaders/top100', {
      method: 'GET',
      headers: headers,
    });
    const data = await response.json();
    return data?.data;
  }