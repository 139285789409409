<template>
  <div class="topPanel">
    <div class="firstLine">
      <div class="left">
        <div class="coinsWrapper">
          <img src="../assets/images/coin.png" alt="">
          <div class="text">
            {{ score }}
          </div>
        </div>

      </div>
      <div class="middle">
        Block #{{ gameNumber }}
      </div>
      <router-link to="leaderboard" class="right">
        <img src="../assets/images/trophy.png" alt="">
      </router-link>
    </div>

    <div class="bestScore">
      Best score:
      <img src="../assets/images/coin.png" alt="">&nbsp;
      <div class="value">{{record}}</div>

    </div>
  </div>

  <div id="game">
    <PhaserGame ref="phaserRef"
                @gameEnd="onGameEnd"
                @gameUpdate="onGameUpdate"
                @current-scene-ready="sceneReady"
    />

  </div>
  <div class="bottomBlock" v-if="isGameEndPopupShowing" :style="{height: `${gameEndPopupPosition}px`}">
    <div class="info">
      <div class="left">
        <div class="textLine">
          <div class="key">Block Score: &nbsp;</div>
          <div class="value">{{ score }}</div>
        </div>
        <div class="textLine">
          <div class="key">Best Score: &nbsp;</div>
          <div class="value">{{ record }}</div>
        </div>
        <div class="textLine"></div>
      </div>
      <div class="right">
        <div class="top">Next Free Block</div>
        <div class="bottom">
          <countdown-timer
              :initialTime="nextFreeGameAt"
              :onTimerEnd="handleTimerEnd"
          ></countdown-timer>
        </div>
      </div>
    </div>
    <button class="newGameButton" @click="onClickNewGame">
      <icon-base><icon-ads></icon-ads></icon-base>
      New Game
    </button>
    <button class="shareButton">Share with your friend</button>

  </div>

</template>

<script>
import PhaserGame from '../game/PhaserGame.vue';
import {endGame, getActiveGame, newGame, resetGame, updateScore, verify} from "@/http";
import StartGame from "@/game/main";
import {getTimestampDifferenceFromCurrentTime} from "@/utils/timeHelper";
import CountdownTimer from '@/components/CountdownTimer/';
import IconBase from "@/components/IconBase";
import IconAds from "@/components/icons/IconAds";
import {finishLoading, gameFinished, getUserProfile, showAd} from "@/platforms/playdeck/playdeck";

export default {
  name: "GameView",
  components: {
    IconBase,
    IconAds,
    PhaserGame,
    CountdownTimer
  },
  data() {
    return {
      isGameEndPopupShowing: false,
      balance: 0,
      record: 0,
      score: 0, // Actual score
      displayedGameProgress: 0, // Displayed score for smooth transition
      sessionId: '',
      gameNumber: 1,
      nextFreeGameAt: '00:00:00',
      gridEndingYPosition: 0,
      userId: null
    }
  },
  computed: {
    gameEndPopupPosition() {
      return window.innerHeight - this.gridEndingYPosition;
    }
  },
  async mounted() {
    const localToken = localStorage.getItem('token');
    const {firstName, lastName, username, telegramId, token} = await getUserProfile() || {};
    if (telegramId) {
      this.userId = telegramId;
    }

    if (!localToken) {
      const {access_token} = await verify({
        token, user_id: telegramId, first_name: firstName, last_name: lastName, username
      });
      if (access_token) {
        localStorage.setItem('token', access_token);
      }
    }


    let userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      const {best_score} = userInfo;
      this.record = best_score;
    }

    const {success, data} = await getActiveGame();
    if (success) {
      const {session_id, score, game_number, game_state} = data;
      this.sessionId = session_id;
      this.score = score;
      this.gameNumber = game_number;

      StartGame('game-container', game_state || {}, true);
    } else {
      this.startNewGame({createCanvas: true});
    }
  },
  methods: {
    sceneReady(scene) {
      this.gridEndingYPosition = scene.getGridEndingYPosition();
      finishLoading();
    },
    async onClickNewGame() {
      const showResult = await showAd();
      if (showResult === 'rewardedAd') {
        await resetGame(this.userId);
        this.startNewGame({createCanvas: false});
      }
    },
    async handleTimerEnd() {
      console.log('TIMER ENDED');
      const started = await this.startNewGame({createCanvas: false});
      if (!started) {
        console.log('second try to start after timer end!');
        setTimeout(() => {
          this.startNewGame({createCanvas: false});
        }, 1000);
      }
    },
    async onGameEnd({gameState}) {
      console.log('onGameEnd', {gameState});
      const { data, success} = await endGame({session_id: this.sessionId});

      if (success) {
        const {user, session, next_free_game_at} = data;
        const {best_score} = user;
        this.record = best_score;
        this.nextFreeGameAt = getTimestampDifferenceFromCurrentTime(next_free_game_at);

        if (this.nextFreeGameAt === '00:00:00') {
          this.startNewGame({createCanvas: false});
          return;
        } else {
          this.isGameEndPopupShowing = true;
        }
      }
    },
    async startNewGame({createCanvas}) {
      console.log('startNewGame')

      const {success, data, message} = await newGame();

      if (success) {
        const {session_id, score, game_number, game_state} = data;

        this.sessionId = session_id;
        this.score = score;
        this.gameNumber = game_number;

        StartGame('game-container', game_state || {}, createCanvas === true);
        this.isGameEndPopupShowing = false;

        return true;
      } else {
        const {available_at, last_game} = data || {};
        const {game_number, game_state, score} = last_game || {};

        this.gameNumber = game_number || 0;
        this.score = score || 0;

        StartGame('game-container', game_state || {}, createCanvas === true);

        if (available_at) {
          this.nextFreeGameAt = getTimestampDifferenceFromCurrentTime(available_at);
          this.isGameEndPopupShowing = true;
        }

        console.error(message);
      }
      return false;
    },
    onGameUpdate({action, data}) {
      console.log('onGameUpdate', {action, data})
      switch (action) {
        case 'scoreUpdate':
          if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
            // Haptic feedback is available, trigger a light impact vibration
            if (data.scoreUpdate >= 9) {
              window.Telegram.WebApp.HapticFeedback.notificationOccurred('success');
            } else {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            }
          }
          this.onScoreUpdate(data);
          break;
        case 'saveGameState':
          try {
            localStorage.setItem('gameState', data.gameStateString);
            console.log('Game state saved to localStorage');
          } catch (error) {
            console.error('Failed to save game state to localStorage:', error);
          }
          break;
      }
    },
    async onScoreUpdate({scoreUpdate, gameState}) {
      const targetScore = this.score + scoreUpdate;

      // Smooth transition to the new score
      this.animateScoreIncrement(this.score, targetScore);

      // Update the score on the server
      const data = await updateScore({
        step_score: scoreUpdate,
        session_id: this.sessionId,
        game_state: gameState
      });
    },
    animateScoreIncrement(current, target) {
      const duration = 150; // duration in milliseconds
      const stepTime = 1000 / 60; // 60 frames per second
      const step = (target - current) / (duration / stepTime); // Calculate how much to increase per frame
      let tempScore = this.score;

      const updateScore = () => {
        tempScore += step;
        if (tempScore < target) {
          if (parseInt(tempScore) !== this.score) {
            this.score = parseInt(tempScore);
          }

          requestAnimationFrame(updateScore); // Continue the animation
        } else {
          this.score = target;
        }
      };

      requestAnimationFrame(updateScore);
    },
  }
}
</script>

<style scoped lang="scss">
@import "../assets/styles/mixins";

#game {
  z-index: 5;
}

.topPanel {
  display: flex;
  flex-direction: column;
  padding: 3px 20px;
  padding-top: 6px;
  position: absolute;
  width: 100vw;
  z-index: 6;


  .firstLine {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left {
    position: absolute;
    left: 20px;

    .coinsWrapper {
      display: flex;
      align-items: center;
      height: 48px;
      border-radius: 10px;
      background-color: #F6F7F8;
      padding: 0 9px;

      img {
        width: 28px;
        height: 28px;
        margin-right: 5px;
      }
      .text {
        @include heavyTitle;
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
      }
    }
  }
  .middle {
    font-size: 20px;
    line-height: 25px;
    padding-top: 10px;

  }
  .right {
    height: 48px;
    border-radius: 10px;
    background-color: #F6F7F8;
    padding: 0 9px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;

    img {
      width: 33px;
      height: 27px;
    }
  }
  .bestScore {
    font-size: 12px;
    line-height: 20px;
    color: #AAAAAA;
    display: flex;
    justify-content: center;
    align-items: center;

    .coinAndWrapper {
      display: flex;
    }

    img {
      width: 12px;
      height: 12px;
      margin-left: 2px;
    }
    .value {
      @include heavyTitle;
      font-size: 15px;
      line-height: 20px;
      color: #707579;
      font-weight: 600;
    }
  }

}

.bottomBlock {
  background-color: white;
  height: 39vh;
  width: 100vw;
  z-index: 5;
  position: fixed;
  bottom: 0;
  padding: 20px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .info {
    display: flex;
    justify-content: space-between;

    .left {
      height: 68px;
      background-color: #EAEDF4;
      border-radius: 10px;
      padding: 10px;

      .textLine {
        display: flex;
        font-weight: 600;
        .key {
          font-size: 16px;
          line-height: 22px;
          color: #707579
        }
        .value {
          font-size: 17px;
          color: black;
          line-height: 22px;
          font-weight: 600;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      .top {
        font-size: 16px;
        line-height: 22px;
        color: #707579;
      }
      .bottom {
        @include heavyTitle;
        font-size: 28px;
        line-height: 34px;
        color: black;
      }
    }
  }

  .newGameButton {
    background-color: #007AFF;
    border-radius: 14px;
    border: none;
    height: 54px;
    font-size: 16px;
    color: white;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .shareButton {
    font-size: 16px;
    color: #007AFF;
    height: 50px;
    border: none;
    //margin-top: 12px;
    background: none;
    font-weight: 600;
  }

  @media (max-height: 700px){
    padding: 5px 20px;
  }
}




.game {
  margin-top: 19px;
}

</style>