<template>
  <path d="M21.7559 2.78027L22.0098 3.7373C22.2735 4.76269 21.7852 5.65136 20.711 5.93457L9.98827 8.80566H21.4141C22.5176 8.80566 23.2207 9.50879 23.2207 10.5928V19.7041C23.2207 21.6182 22.2539 22.5947 20.291 22.5947H4.23631C2.28319 22.5947 1.29686 21.6279 1.29686 19.7041V11.1494L0.56444 8.46386C0.0761589 6.61816 0.789049 5.41699 2.65428 4.90918L18.1621 0.739254C20.0371 0.241207 21.2774 0.915035 21.7559 2.78027ZM7.60545 10.3291L5.05663 10.4463L3.66014 13.044H6.87303L8.33788 10.3291H7.60545ZM11.3164 10.3291L9.86131 13.044H13.3086L14.7735 10.3291H11.3164ZM17.752 10.3291L16.2969 13.044H19.5L20.9649 10.3291H17.752ZM3.92381 6.14941L3.19139 9.14746L6.30663 8.30761L7.00975 5.30957L3.92381 6.14941ZM9.90038 4.53808L9.19725 7.53613L12.5274 6.65722L13.2305 3.64941L9.90038 4.53808ZM18.4649 2.2334L16.1114 2.87793L15.3985 5.87597L18.5039 5.0459L19.1973 2.06738C18.9629 2.11621 18.7188 2.16504 18.4649 2.2334Z" fill="white" fill-opacity="0.85"/>
</template>

<script>
export default {
  name: "IconAds"
}
</script>

<style scoped>

</style>