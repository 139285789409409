import { Game as MainGame } from './scenes/Game';
import { AUTO, Game, Scale } from 'phaser';

//  Find out more information about the Game Config at:
//  https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig

const SIZE_WIDTH_SCREEN = 365*3-300;
const SIZE_HEIGHT_SCREEN = 400*4;

const config = {
    type: AUTO,
    parent: 'game-container',
    transparent: true,
    antialias: true,
    scene: MainGame,
    scale: {
        // mode: Scale.HEIGHT_CONTROLS_WIDTH,
        mode: Scale.FIT,
        width: SIZE_WIDTH_SCREEN,
        height: SIZE_HEIGHT_SCREEN,
    },
    callbacks: {
        postBoot({scene}) {
            scene.scenes[0].resize();
        }
    }
};

let game;

const StartGame = (parent, gameState, createCanvas) => {
    console.log('StartGame', parent)
    if (!game || createCanvas) {
        game = new Game({ ...config, parent });
        game.scene.start('Game', gameState);
    } else {
        game.scene.start('Game', gameState);
    }
}

export default StartGame;

