<template>
  <svg :width="width" :height="height" :viewBox="`0 0 ${viewBoxWidth} ${viewBoxHeight}`" fill="none" xmlns="http://www.w3.org/2000/svg">
    <slot />
  </svg>

</template>

<script>
export default {
  name: "IconBase",
  props: {
    width: {
      type: Number,
      default: 29
    },
    height: {
      type: Number,
      default: 28
    },
    viewBoxWidth: {
      type: Number,
      default: 29
    },
    viewBoxHeight: {
      type: Number,
      default: 28
    },
  }
}
</script>

<style scoped>

</style>