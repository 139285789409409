<template>
  <router-view/>
</template>

<style>

@import "modern-css-reset";
@import "./assets/styles/main.css";

#app {
  font-family: "San Francisco", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /*margin-bottom: 66px;!* footer height *!*/
}

body {
  background-color: white;
  user-select: none;
  overflow: hidden;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import {accountInfo, verify} from "@/http";
import {getToken, getUserProfile} from "@/platforms/playdeck/playdeck";
export default {
  components: {},
  async mounted() {

  },
  methods: {
  }
}
</script>