import {Scene, Actions, Math as PhaserMath, Display} from 'phaser';
import Phaser from 'phaser';
import {EventBus} from '../EventBus';

import {
    gridY,
    cellWidth,
    cellHeight,
    distanceBetweenCells,
    rows,
    cols,
    cellColor,
    cellColor2,
    cellColorHover,
    cellsWithAnotherColor,
    figureColorsConfig,
    figuresConfig,
    drag,
    draggableFiguresPosition,
    figureScale,
    failColor,
} from '../config.json';

export class Game extends Scene {
    constructor() {
        super('Game');
    }

    preload() {
        this.load.image('blockTexture', 'block_brick.png');
        this.load.audio('block-put', ['block-put.m4a', 'block-put.mp3']);
        this.load.audio('block-destroy', ['block-destroy.m4a', 'block-destroy.mp3']);

        // Add an invisible text object to preload the font
        this.load.on('complete', () => {
            const preloadText = this.add.text(-1000, -1000, '', {
                fontFamily: 'San Francisco Heavy',
                fontSize: '1px'
            });
            preloadText.destroy();
        });
    }

    create(gameState) {
        console.log(gameState);

        if (gameState) {
            this.isContinue = this.loadGameState(gameState);
        } else {
            this.isContinue = false;
        }
        window.scene = this;

        this.gridWidth = cols * (cellWidth + distanceBetweenCells);
        this.gridX = this.cameras.main.centerX - (this.gridWidth / 2) + distanceBetweenCells / 2;
        this.gridY = gridY;

        this.prepareCellTexture();

        if (!this.isContinue) {
            this.gridState = Array(9).fill().map(() => Array(9).fill(0));
            this.gridColors = Array(9).fill().map(() => Array(9).fill(figureColorsConfig[0]));
        }

        this.gridStateHypothetical = this.gridState.map(row => [...row]);

        this.createGrid();

        this.colorizeCells();

        if (this.isContinue) {
            this.createFiguresByIds();

            // disable figures and check end just in case.
            // const isGameEnd = this.checkGameEnd();
            // if (isGameEnd) {
            //     setTimeout(() => {
            //         EventBus.emit('game-end', {gameState: this.getGameState()});
            //     }, 800);
            // }
        } else {
            this.filledCellsGroup.getChildren().forEach((cell, index) => {
                const color = figureColorsConfig[index % figureColorsConfig.length];  // Cycle through colors
                cell.setTint(color);  // Set the tint of the cell
            });

            // Begin animation for new game.
            this.filledCellsGroup.setAlpha(1);
            // this.colorizeCells({}, [], failColor);

            const final = (tween, targets) => {
                this.filledCellsGroup.setAlpha(0);
                targets.forEach(sprite => {
                    sprite.rotation = 0;
                    sprite.scale = 1;
                });
            };

            this.startCellsAnimTween = this.tweens.add({
                targets: this.filledCellsGroup.getChildren(),
                scale: 0,
                angle: 360,
                ease: 'linear',
                duration: 130,
                delay: this.tweens.stagger(10),
                onStop: final,
                onComplete: final,
            });


            this.createNewRandomFiguresForDrag();

            // this.saveGameState();
        }

        this.input.on('dragstart', (pointer, gameObject) => {

            if (this.startCellsAnimTween){
                this.startCellsAnimTween.stop();
            }

            this.draggableSprite = gameObject;

            const figureTweens = this.tweens.getTweensOf(gameObject);
            figureTweens.forEach(t => t.stop());

            this.tweens.killTweensOf(gameObject);

            // Create a scale-up tween and store its reference
            this.tweens.add({
                targets: gameObject,
                scaleX: 1, // Target scale for X axis
                scaleY: 1, // Target scale for Y axis
                duration: 100, // Duration of the tween in milliseconds
                ease: 'Power2', // Easing function for smooth animation
            });

            // this.draggableSprite = gameObject;
            // this.dragFigureIndexes = false;
            this.dragFigureIndexes = [];
        });

        this.input.on('drag', (pointer, gameObject, dragX, dragY) => {
            gameObject.x = dragX;
            gameObject.y = dragY + drag.correctionY;

            this.draggableSprite = gameObject;

            this.dragFigureIndexes = this.onFigureDrag({sprite: gameObject, figureConfig: gameObject.figureConfig});
        });

        this.input.on('dragend', () => {
            this.onFigureDragEnd()
        });

        const isGameEnd = this.checkGameEnd();
        if (isGameEnd) {
            this.colorizeCells({}, [], failColor);
        }

        EventBus.emit('current-scene-ready', this);
    }

    getGameState() {
        // Convert the game state to a JSON string
        return {
            figureIds: this.figureIds,
            figureColors: this.figureColors,

            gridState: this.gridState,
            gridColors: this.gridColors,
        };
    }

    // saveGameState() {
    //     const gameStateString = this.getGameState();
    //
    //     EventBus.emit('game-update', {action: 'saveGameState', data: {gameStateString}});
    // }

    loadGameState({figureIds, figureColors, gridState, gridColors}) {
        this.figureIds = figureIds || [];
        this.figureColors = figureColors || Array(3).fill(figureColorsConfig[0]);

        this.gridState = gridState || Array(9).fill().map(() => Array(9).fill(0));
        this.gridColors = gridColors || Array(9).fill().map(() => Array(9).fill(figureColorsConfig[0]));

        return !!this.figureIds.length;
    }

    playSound(soundName, volume = 1) {
        if (this.sound.locked)
            return;

        const sound = this.sound.add(soundName, {volume: volume});
        sound.play();
    }

    createNewRandomFiguresForDrag() {
        this.figuresSprites = [];
        this.figureIds = [];
        this.figureColors = [];

        const figuresLength = figuresConfig.length;
        const figureColorsLength = figureColorsConfig.length;

        for (let i = 0; i < 3; i++) {
            const id = PhaserMath.RND.between(1, figuresLength);
            const color = figureColorsConfig[PhaserMath.RND.between(0, figureColorsLength - 1)];
            this.createFigure({id, position: i + 1, color});

            this.figureIds.push(id);
            this.figureColors.push(color);
        }

        this.draggableFiguresCount = 3;

        this.createFiguresAnimation();

    }

    createFiguresByIds() {
        this.figuresSprites = [];
        for (let i = 0; i < 3; i++) {
            if (this.figureIds[i] < 1) {
                continue;
            }
            this.createFigure({id: this.figureIds[i], position: i + 1, color: this.figureColors[i]}, figureScale);
        }

        this.draggableFiguresCount = this.figureIds.filter(id => id > 0).length;

        // this.chainTweenAnimations();
    }

    createFiguresAnimation() {
        this.tweens.add({
            targets: this.figuresSprites,
            scale: figureScale,
            angle: 360,
            ease: 'linear',
            duration: 300,
            delay: this.tweens.stagger(100),
            onStop: (tween, targets) => {
                targets.forEach(sprite => {
                    sprite.rotation = 0;
                    sprite.scale = figureScale;
                })
            }
        });
    }

    updateGridState(newFilledIndexes, grid) {
        let index = 0;
        for (let row = 0; row < rows; row++) {

            for (let col = 0; col < cols; col++) {
                if (newFilledIndexes.includes(index)) {
                    grid[row][col] = 1;
                }
                index++;
            }
        }
    }

    updateGridColors(newFilledIndexes, color) {
        let index = 0;
        for (let row = 0; row < rows; row++) {

            for (let col = 0; col < cols; col++) {
                if (newFilledIndexes.includes(index)) {
                    this.gridColors[row][col] = color;
                }
                index++;
            }
        }
    }

    prepareCellTexture() {
        const graphics = this.make.graphics({x: 0, y: 0, add: false});

        graphics.fillStyle(0xFFFFFF, 1);

        graphics.fillRect(0, 0, cellWidth, cellHeight);

        graphics.generateTexture('roundedRect', cellWidth, cellHeight);

        // Clean up the graphics object
        graphics.destroy();
    }

    createGrid() {
        this.add.rectangle(this.gridX - distanceBetweenCells*2, this.gridY - distanceBetweenCells*2,
            this.gridWidth + distanceBetweenCells*3, this.gridWidth + distanceBetweenCells*3, 0xDFE4EB)
            .setOrigin(0, 0);

        this.cellsGroup = this.add.group();
        this.filledCellsGroup = this.add.group();

        const backgroundWidth = this.gridWidth + 20 - distanceBetweenCells;
        const backgroundHeight = this.gridWidth + 20 - distanceBetweenCells;

        const backgroundX = this.gridX - 10;
        const backgroundY = this.gridY - 10;

        // Create the background roundedRect
        const background = this.add.rectangle(
            backgroundX + backgroundWidth / 2,  // Center the rectangle
            backgroundY + backgroundHeight / 2,  // Center the rectangle
            backgroundWidth,
            backgroundHeight,
            0xCED3E1  // Background color (adjust as needed)
        );
        // background.setStrokeStyle(20, 0xDFE4EB);

        this.cellsGroup.createMultiple({
            key: 'roundedRect',
            quantity: rows * cols
        });

        this.filledCellsGroup.createMultiple({
            key: 'blockTexture',
            quantity: rows * cols
        });

        this.cellsGroup.setTint(cellColor);
        // this.filledCellsGroup.setTint(activeFigureColor);
        this.filledCellsGroup.setAlpha(0);

        Actions.GridAlign(this.cellsGroup.getChildren(), {
            width: cols,
            height: rows,
            cellWidth: cellWidth + distanceBetweenCells,
            cellHeight: cellHeight + distanceBetweenCells,
            x: this.gridX,
            y: this.gridY
        });

        Actions.GridAlign(this.filledCellsGroup.getChildren(), {
            width: cols,
            height: rows,
            cellWidth: cellWidth + distanceBetweenCells,
            cellHeight: cellHeight + distanceBetweenCells,
            x: this.gridX,
            y: this.gridY
        });


    }

    createFigure({id, position, color}, initialScale = 0) {
        const figureConfig = this.getFigureConfigById(id);
        const {width, height} = this.getFigureSizes(figureConfig);

        let texture = this.textures.list[`figure_${id}`];

        if (!texture) {
            texture = this.textures.addDynamicTexture(`figure_${id}`, width, height);

            // const blockTexture = this.textures.get('blockTexture');

            // // Access the first frame of the texture
            // const frame = blockTexture.get(0); // or blockTexture.getFrame()

            // // Get the width and height of the frame
            // const originalWidth = frame.width;
            // const originalHeight = frame.height;
            // const scaleX = cellWidth / originalWidth;
            // const scaleY = cellHeight / originalHeight;

            for (let row in figureConfig) {
                for (let col in figureConfig[row]) {
                    const cell = figureConfig[row][col];

                    if (cell) {
                        texture.stamp('blockTexture', 0,
                            (+col + 1) * cellWidth + +col * distanceBetweenCells - cellWidth / 2,
                            (+row + 1) * cellHeight + +row * distanceBetweenCells - cellHeight / 2); // {scaleX, scaleY }

                        //     const x = col * (cellWidth + distanceBetweenCells);
                        // const y = row * (cellHeight + distanceBetweenCells);

                        // // Stamp the texture with the correct scaling
                        // texture.stamp('blockTexture', 0, x, y, { originX: 0, originY: 0, scaleX, scaleY });
                    }
                }
            }
        }

        const {rows: figureRows, cols: figureCols} = this.getFigureRowsColsCount(figureConfig);

        const {x, y} = this.getDefaultPositionForDraggableSprite(position);

        const newSprite = this.add.sprite(x, y, texture);
        newSprite.tint = color;
        newSprite.setOrigin(0.5, 0.5);
        //newSprite.setScale(0.75);
        newSprite.setScale(initialScale);

        // newSprite.setInteractive(new Geom.Rectangle(-50, -50, newSprite.width + 100, newSprite.height + 100), Input.InputPlugin.NO_DROP);

        let rx;
        let ry;
        const lifehackSize = 100;
        switch (figureRows) {
            case 1:
                rx = -lifehackSize;
                break;

            case 2:
                rx = -lifehackSize / 2;
                break;

            case 3:
                rx = 0;
                break;

            case 4:
                rx = lifehackSize / 2;
                break;

            default:
                break;
        }

        switch (figureCols) {
            case 1:
                ry = -lifehackSize;
                break;

            case 2:
                ry = -lifehackSize / 2;
                break;

            case 3:
                ry = 0;
                break;

            case 4:
                ry = lifehackSize / 2;
                break;

            default:
                break;
        }

        const hitArea = new Phaser.Geom.Rectangle(ry, rx, lifehackSize * 3, lifehackSize * 3 + 200);

        // newSprite.setOrigin(0, 0);
        // newSprite.setInteractive(hitArea, Phaser.Geom.Rectangle.Contains);
        newSprite.setInteractive({
            hitArea: hitArea,
            hitAreaCallback: Phaser.Geom.Rectangle.Contains,
            useHandCursor: true,
            debug: true  // Enable debug
        });
        this.input.setDraggable(newSprite);

        // newSprite.setInteractive(hitArea, Geom.Rectangle.Contains);

        newSprite.figureConfig = figureConfig;
        newSprite.draggablePosition = position;

        this.figuresSprites.push(newSprite);
    }

    getDefaultPositionForDraggableSprite(position) {
        let x;

        switch (position) {
            case 1:
                x = this.gridX + cellWidth * 1.5 + distanceBetweenCells;
                break;
            case 2:
                x = this.cameras.main.centerX;
                break;
            case 3:
                x = this.cameras.main.centerX + cellWidth * 3 + distanceBetweenCells * 3;
                break;
        }

        const y = this.gridY + cellHeight * rows + distanceBetweenCells * (rows - 1) + draggableFiguresPosition.y;

        return {x, y};
    }

    onFigureDrag({sprite, figureConfig}) {
        const cellIndex = this.getCellIndexByXY(sprite.getTopLeft());
        let figureIndexes = [];
        let combinedIndexes;
        let destroyIndexesMap;

        if (cellIndex !== -1) {
            figureIndexes = this.isFigureFit({cellIndex, figureConfig});

            if (figureIndexes !== false) {
                for (let row = 0; row < rows; row++) {
                    for (let col = 0; col < cols; col++) {
                        this.gridStateHypothetical[row][col] = this.gridState[row][col];
                    }
                }

                this.updateGridState(figureIndexes, this.gridStateHypothetical);

                const {indexes: destroyIndexes} = this.checkLinesAnd3x3(this.gridStateHypothetical);

                combinedIndexes = [...new Set([...figureIndexes, ...destroyIndexes])];

                destroyIndexesMap = combinedIndexes.map(index => destroyIndexes.includes(index));

                //console.log(`combined indexes: ${combinedIndexes}`);
                //console.log(`destroy indexes map: ${destroyIndexesMap}`);
            }
        }
        this.colorizeCells({figureIndexes: combinedIndexes}, destroyIndexesMap);
        return figureIndexes;
    }

    onFigureDragEnd() {
        if (this.dragFigureIndexes.length > 0) {
            this.updateGridState(this.dragFigureIndexes, this.gridState);

            const draggedFigureColor = this.draggableSprite.tint;
            this.updateGridColors(this.dragFigureIndexes, draggedFigureColor);

            this.colorizeCells();

            this.draggableFiguresCount--;

            const index = this.figuresSprites.findIndex(figure => figure.draggablePosition === this.draggableSprite.draggablePosition);
            if (index !== -1) {
                // If an element is found, remove it from the array
                this.figuresSprites.splice(index, 1);
                this.figureIds[this.draggableSprite.draggablePosition - 1] = -1;
            }

            // TODO: Object pool?
            this.draggableSprite.destroy();

            if (this.draggableFiguresCount <= 0) {
                this.createNewRandomFiguresForDrag();
            }


            const scoreUpdate = this.doLinesAnd3x3(this.dragFigureIndexes, draggedFigureColor);

            // this.saveGameState();

            if (scoreUpdate >= 9) {
                this.playSound("block-destroy");
            } else {
                this.playSound("block-put");
            }

            const isGameEnd = this.checkGameEnd();
            if (isGameEnd) {
                setTimeout(() => {
                    this.colorizeCells({}, [], failColor);
                    EventBus.emit('game-end', {gameState: this.getGameState()});
                }, 800);
            }
        } else {
            if (this.draggableSprite) {
                const {x, y} = this.getDefaultPositionForDraggableSprite(this.draggableSprite.draggablePosition);
                this.draggableSprite.setPosition(x, y);

                this.tweens.killTweensOf(this.draggableSprite);
                // Create a scale-up tween and store its reference
                this.tweens.add({
                    targets: this.draggableSprite,
                    scaleX: figureScale, // Target scale for X axis
                    scaleY: figureScale, // Target scale for Y axis
                    duration: 100, // Duration of the tween in milliseconds
                    ease: 'Power2', // Easing function for smooth animation
                });
            }
        }
    }

    checkGameEnd() {

        // console.log("Number of figures: " + this.figuresSprites.length);

        let gameEnd = true;

        this.figuresSprites.forEach((figure) => {
            const canBePlaced = this.cellsGroup.getChildren().some((cell, index) =>
                this.isFigureFit({ cellIndex: index, figureConfig: figure.figureConfig })
            );

            if (canBePlaced) {
                figure.setInteractive(true);
                figure.setAlpha(1);

                gameEnd = false;

            } else {
                figure.setInteractive(false);
                figure.setAlpha(0.5);
            }
        });

        return gameEnd;
    }

    getFigureConfigById(id) {
        return figuresConfig.find(config => config.id === id).cells;
    }

    getCellIndexByXY({x, y}) {
        // console.log(x,y)
        // return this.cellsGroup.getChildren()
        //     .findIndex(cell => (x >= cell.x - cellWidth / 2 && x < cell.x + cellWidth / 2) &&
        //         (y >= cell.y - cellHeight / 2 && y < cell.y + cellHeight / 2));

        // Calculate the cell's x and y index based on the coordinates
        const gridX = x - this.gridX;
        const gridY = y - this.gridY;

        // Adjust for any possible misalignment by adding half the cell width and height
        const cellX = Math.floor((gridX + (cellWidth + distanceBetweenCells) / 2) / (cellWidth + distanceBetweenCells));
        const cellY = Math.floor((gridY + (cellHeight + distanceBetweenCells) / 2) / (cellHeight + distanceBetweenCells));

        // Ensure the cellX and cellY are within bounds
        // const clampedCellX = Math.max(0, Math.min(cols - 1, cellX));
        // const clampedCellY = Math.max(0, Math.min(rows - 1, cellY));

        if (cellX < 0 || cellX > cols - 1)
            return -1;
        if (cellY < 0 || cellY > rows - 1)
            return -1;

        // Convert 2D grid position to a 1D cell index
        const cellIndex = cellY * cols + cellX;

        return cellIndex;
    }

    getRowColByCellIndex(cellIndex) {
        return {
            row: Math.floor(cellIndex / rows),
            col: cellIndex % cols,
        }
    }

    getCellIndexByRowCol(x, y) {
        return x * 9 + y;
    }

    getFigureRowsColsCount(figureConfig) {
        return {
            rows: figureConfig.length,
            cols: figureConfig[0].length
        }
    }

    getFigureSizes(figureConfig) {
        const {rows: figureRows, cols: figureCols} = this.getFigureRowsColsCount(figureConfig);

        const width = (figureCols - 1) * distanceBetweenCells + figureCols * cellWidth;
        const height = (figureRows - 1) * distanceBetweenCells + figureRows * cellHeight;
        return {
            width, height
        };
    }

    isFigureFit({cellIndex, figureConfig}) {

        const {row: selectedRow, col: selectedCol} = this.getRowColByCellIndex(cellIndex);
        const {rows: figureRows, cols: figureCols} = this.getFigureRowsColsCount(figureConfig);

        if (figureRows > rows - selectedRow || figureCols > cols - selectedCol) {
            return false;
        }

        const figureIndexes = [];

        for (let row = selectedRow; row < selectedRow + figureRows; row++) {
            for (let col = selectedCol; col < selectedCol + figureCols; col++) {
                if (figureConfig[row - selectedRow][col - selectedCol]) {
                    if (this.gridState[row][col]) {
                        return false;
                    } else {
                        figureIndexes.push(row * cols + col);
                    }
                }
            }
        }

        return figureIndexes;
    }

    colorizeCells({figureIndexes} = {}, destroyIndexesMap = [], overrideColor = null) {
        this.cellsGroup.getChildren().forEach((cell, index) => {
            const {row, col} = this.getRowColByCellIndex(index);

            cell.tint = cellColor;

            if (cellsWithAnotherColor.includes(index)) {
                cell.tint = cellColor2;
            }
            if (this.gridState[row][col]) {
                const filledCell = this.filledCellsGroup.getChildren()[index];
                if (figureIndexes && figureIndexes.includes(index)) {
                    filledCell.tint = this.draggableSprite.tint;
                    filledCell.setAlpha(1);
                }
                else {
                    filledCell.tint = overrideColor || this.gridColors[row][col];
                    filledCell.setAlpha(1);
                }
            }
            if (figureIndexes && figureIndexes.includes(index)) {

                const figureIndexPosition = figureIndexes.indexOf(index);
                if (destroyIndexesMap[figureIndexPosition]) {
                    cell.tint = this.draggableSprite.tint;
                } else {
                    cell.tint = cellColorHover;
                }

            }

            // if (figureIndexes && figureIndexes.includes(index)) {
            //     // filledCell.tint = cellColorHover;
            //     filledCell.setAlpha(0);
            // }
            // else {
            //     filledCell.tint = activeFigureColor;
            //     filledCell.setAlpha(1);
            // }
        });
    }

    check3x3Subgrid(startX, startY, grid) {
        const indexes = [];
        // Check
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                if (grid[startX + i][startY + j] !== 1) {
                    return[];
                }
            }
        }

        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                const oneDimensionalIndex = (startX + i) * 9 + (startY + j);
                indexes.push(oneDimensionalIndex);
            }
        }
        return indexes;
    }


    checkLinesAnd3x3(grid) {
        let indexes = [];
        let winCombinations = 0;

        for (let row = 0; row < rows; row++) {

            if (grid[row].every(val => val === 1)) {

                winCombinations++;

                for (let col = 0; col < 9; col++) {
                    // Calculate the one-dimensional index for the cell
                    const oneDimensionalIndex = row * 9 + col;
                    indexes.push(oneDimensionalIndex);
                }
            }
        }

        for (let col = 0; col < cols; col++) {
            let columnFilled = true;
            // Check if the entire column is filled with 1s
            for (let row = 0; row < rows; row++) {
                if (grid[row][col] !== 1) {
                    columnFilled = false;
                    break;
                }
            }

            // If the column is filled with 1s, add the indices to the `indexes` array
            if (columnFilled) {
                winCombinations++;
                for (let row = 0; row < rows; row++) {
                    const oneDimensionalIndex = row * rows + col;
                    indexes.push(oneDimensionalIndex);
                }
            }
        }

        for (let startX = 0; startX <= 6; startX += 3) {
            for (let startY = 0; startY <= 6; startY += 3) {
                const cubicIndexes = this.check3x3Subgrid(startX, startY, grid)
                if (cubicIndexes.length) {
                    winCombinations++;
                }
                indexes = [...indexes, ...cubicIndexes];
            }
        }



        return {indexes, winCombinations};
    }

    doLinesAnd3x3(placedIndexes, scoreTextColor) {
        let {indexes: destroyIndexes, winCombinations} = this.checkLinesAnd3x3(this.gridState);

        // te kybiki kotorie postavil i ne vzorvalis.
        const nonDestroyIndexes = placedIndexes.filter(index => !destroyIndexes.includes(index));
        const scoreUpdate = nonDestroyIndexes.length + destroyIndexes.length * winCombinations;

        // Calculate boundaries
        const allIndexes = placedIndexes.concat(destroyIndexes);
        const positions = allIndexes.map(index => this.filledCellsGroup.getChildren()[index]);

        const leftmost = Math.min(...positions.map(p => p.x));
        const rightmost = Math.max(...positions.map(p => p.x));
        const topmost = Math.min(...positions.map(p => p.y));
        const bottommost = Math.max(...positions.map(p => p.y));

        // Calculate the midpoint
        const midpointX = (leftmost + rightmost) / 2;
        const midpointY = (topmost + bottommost) / 2;

        if (destroyIndexes.length > 0) {

            // Ensure scoreUpdate is accessible within the animate callback
            this.animate(destroyIndexes, () => {
                this.createScoreText(scoreUpdate, { x: midpointX, y: midpointY }, scoreTextColor);
            });
        }

        console.log(`scoreUpdate: ${scoreUpdate}`);

        // if (winCombinations > 0) {
        EventBus.emit('game-update', {
            action: 'scoreUpdate', data: {
                scoreUpdate, gameState: this.getGameState()
            }
        });
        // }

        // Loop through each index and reset the corresponding gridState element to zero
        destroyIndexes.forEach(index => {
            const {row, col} = this.getRowColByCellIndex(index);
            // Reset the element in gridState to zero
            this.gridState[row][col] = 0;
        });

        return scoreUpdate;
    }

    createScoreText(score, position, textColor) {
        // Create the initial score text
        let displayText = `+${score}`;

        // Add additional lines for winCombinations if greater than 1
        // if (winCombinations > 1) {
        //     displayText += `\nx${winCombinations}`;
        // }

        const colorObject = Display.Color.IntegerToColor(textColor);
        const colorString = `rgb(${colorObject.red}, ${colorObject.green}, ${colorObject.blue})`;

        const scoreText = this.add.text(position.x, position.y, displayText, {
            fontFamily: "San Francisco Heavy",
            fontSize: '80px',
            fill: colorString,
            // backgroundColor: '#fff',
            padding: { x: 10, y: 5 },
            align: 'center',
            stroke: '#fff',
            strokeThickness: 10,
        }).setOrigin(0.5);

        // Add a tween to animate the text
        this.tweens.add({
            targets: scoreText,
            y: position.y - 100, // Move up by 100 pixels
            alpha: 0, // Fade out
            duration: 1700, // Duration of the tween (1.5 second)
            ease: 'Power1', // Easing function
            onComplete: () => {
                // TODO: use Object Pool.
                scoreText.destroy(); // Destroy the text after the tween ends
            }
        });
    }


    animate(indexes, completeFunc) {
        return new Promise(resolve => {
            const targets = this.filledCellsGroup.getChildren().filter((child, index) => indexes.includes(index));

            this.tweens.add({
                targets,
                scale: 0,
                angle: 360,
                ease: 'linear',
                duration: 300,
                delay: this.tweens.stagger(50, {from: 'center'}),
                onComplete: () => {
                    targets.forEach(sprite => {
                        sprite.setScale(1);
                        sprite.setAlpha(0);
                    });
                    resolve(targets);

                    completeFunc();
                }
            });
        });
    }

    resize() {
        const aspectRatio = window.innerWidth/window.innerHeight;
        if (aspectRatio > 0.58) {
            this.scale.setGameSize(
                this.scale.gameSize.width+this.scale.gameSize.width * (aspectRatio - 0.3),
                this.scale.gameSize.height+this.scale.gameSize.height * (aspectRatio - 0.3));
        }
    }

    getGridEndingYPosition() {
        return (this.cellsGroup.getChildren()[80].y + cellHeight) / this.scale.displayScale.y;
    }
}
