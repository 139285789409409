<template>
  <div>
    {{ time }}
  </div>
</template>

<script>
export default {
  props: {
    initialTime: {
      type: String,
      required: true
    },
    onTimerEnd: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      timer: null, // Для хранения таймера
      time: '00:00:00' // Для отображаемого времени
    };
  },
  watch: {
    initialTime(newValue) {
      console.log('watch initialTime', newValue)
      this.startTimer(newValue);
    }
  },
  created() {
    console.log('created', this.initialTime)
    this.startTimer(this.initialTime);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    startTimer(time) {
      if (this.timer) {
        clearInterval(this.timer);
      }

      const [hours, minutes, seconds] = time.split(':').map(Number);
      if(hours === 0 && minutes === 0 && seconds === 0) {
        return;
      }

      let totalSeconds = hours * 3600 + minutes * 60 + seconds;

      const updateTime = () => {
        if (totalSeconds >= 0) {
          const hrs = Math.floor(totalSeconds / 3600);
          const mins = Math.floor((totalSeconds % 3600) / 60);
          const secs = totalSeconds % 60;

          this.time = `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
          totalSeconds--;
        } else {
          clearInterval(this.timer);
          if (typeof this.onTimerEnd === 'function') {
            this.onTimerEnd();
          }
        }
      };

      this.timer = setInterval(updateTime, 1000);

      updateTime();
    }
  }
};
</script>

<style scoped>
</style>
