// Creating an event listener playdeck

const userInfoPromise = new Promise(resolve => {
    window.addEventListener('message', ({ data }) => {
        if (!data['playdeck']) return;

        const pdData = data['playdeck'];

        if (['getToken', 'getUserProfile'].includes(pdData.method)) {
            console.log(pdData.value);
        }

        if (pdData.method === 'getUserProfile') {
            resolve(pdData.value)
        }
    });
});

const { parent } = window

export function getToken() {
    parent.postMessage({ playdeck: { method: 'getToken' } }, '*');
}

export function getUserProfile() {
    parent.postMessage({ playdeck: { method: 'getUserProfile' } }, '*');
    return userInfoPromise;
}

export function finishLoading() {
    parent.postMessage({ playdeck: { method: 'loading', value: 100 } }, '*');
}

export function gameFinished() {
    parent.postMessage({ playdeck: { method: 'gameEnd' } }, '*');
}

export function showAd() {

    return new Promise(resolve => {
        parent.postMessage({ playdeck: { method: "showAd"} },'*');

        const adListener = function ({ data }) {
            const playdeck = data?.playdeck;
            if (!playdeck) return;

            if (['rewardedAd', 'errAd', 'skipAd', 'notFoundAd'].includes(playdeck.method)) {
                console.log(playdeck.method);

                window.removeEventListener('message', adListener);
                resolve(playdeck.method)
            }
        }
        window.addEventListener('message', adListener);
    })
}