export function stringToColor(name) {
    // Generate a hash from the name
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash to HSL values
    const hue = hash % 360; // Limit hue to 0-360 degrees
    const saturation = 60 + (hash % 20); // 60% to 80% saturation
    const lightness = 50 + (hash % 10); // 50% to 60% lightness

    return `${hue}, ${saturation}%, ${lightness}%`;
}