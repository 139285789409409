export function formatTime(ms) {
    const hours = Math.floor(ms / 3600);
    const minutes = Math.floor((ms % 3600) / 60);
    const seconds = ms % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export function getUtcNowTimestamp() {
    const now = new Date();
    const nowUTC = new Date(now.toUTCString());
    return +(nowUTC.getTime() / 1000);
}

export function getTimestampDifferenceFromCurrentTime(timestamp) {
    const utcNow = getUtcNowTimestamp();
    const timestampDifference = timestamp - utcNow;
    return formatTime(timestampDifference >= 0 ? timestampDifference : 0);
}